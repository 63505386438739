/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * SAFc Backend
 * The 24/7 API description
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  LandingPageDataResponse,
  PublicControllerGetUnitsParams,
  PublicExportRequest,
  PublicUnitResponse,
  PublicUnitResponseItem,
  SimpleUserResponse
} from './sAFcBackend.schemas'
import publicControllerGetMeMutator from '../response-type';
import publicControllerGetUnitsMutator from '../response-type';
import publicControllerGetLandingPageStatsMutator from '../response-type';
import publicControllerGetUnitByIdMutator from '../response-type';
import publicControllerExportMutator from '../response-type';
import { customFormData } from '../../mutators/custom-file-data';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const publicControllerGetMe = (
    
 options?: SecondParameter<typeof publicControllerGetMeMutator>,signal?: AbortSignal
) => {
      
      
      return publicControllerGetMeMutator<SimpleUserResponse>(
      {url: `/api/public/maybe-me`, method: 'get', signal
    },
      options);
    }
  

export const getPublicControllerGetMeQueryKey = () => {
    
    return [`/api/public/maybe-me`] as const;
    }

    
export const getPublicControllerGetMeQueryOptions = <TData = Awaited<ReturnType<typeof publicControllerGetMe>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetMe>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetMeMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPublicControllerGetMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof publicControllerGetMe>>> = ({ signal }) => publicControllerGetMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetMe>>, TError, TData> & { queryKey: QueryKey }
}

export type PublicControllerGetMeQueryResult = NonNullable<Awaited<ReturnType<typeof publicControllerGetMe>>>
export type PublicControllerGetMeQueryError = unknown

export const usePublicControllerGetMe = <TData = Awaited<ReturnType<typeof publicControllerGetMe>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetMe>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetMeMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPublicControllerGetMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const publicControllerGetUnits = (
    params: PublicControllerGetUnitsParams,
 options?: SecondParameter<typeof publicControllerGetUnitsMutator>,signal?: AbortSignal
) => {
      
      
      return publicControllerGetUnitsMutator<PublicUnitResponse>(
      {url: `/api/public/units`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getPublicControllerGetUnitsQueryKey = (params: PublicControllerGetUnitsParams,) => {
    
    return [`/api/public/units`, ...(params ? [params]: [])] as const;
    }

    
export const getPublicControllerGetUnitsQueryOptions = <TData = Awaited<ReturnType<typeof publicControllerGetUnits>>, TError = unknown>(params: PublicControllerGetUnitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnits>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetUnitsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPublicControllerGetUnitsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof publicControllerGetUnits>>> = ({ signal }) => publicControllerGetUnits(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnits>>, TError, TData> & { queryKey: QueryKey }
}

export type PublicControllerGetUnitsQueryResult = NonNullable<Awaited<ReturnType<typeof publicControllerGetUnits>>>
export type PublicControllerGetUnitsQueryError = unknown

export const usePublicControllerGetUnits = <TData = Awaited<ReturnType<typeof publicControllerGetUnits>>, TError = unknown>(
 params: PublicControllerGetUnitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnits>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetUnitsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPublicControllerGetUnitsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const publicControllerGetLandingPageStats = (
    
 options?: SecondParameter<typeof publicControllerGetLandingPageStatsMutator>,signal?: AbortSignal
) => {
      
      
      return publicControllerGetLandingPageStatsMutator<LandingPageDataResponse>(
      {url: `/api/public/landing-page`, method: 'get', signal
    },
      options);
    }
  

export const getPublicControllerGetLandingPageStatsQueryKey = () => {
    
    return [`/api/public/landing-page`] as const;
    }

    
export const getPublicControllerGetLandingPageStatsQueryOptions = <TData = Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetLandingPageStatsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPublicControllerGetLandingPageStatsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>> = ({ signal }) => publicControllerGetLandingPageStats(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>, TError, TData> & { queryKey: QueryKey }
}

export type PublicControllerGetLandingPageStatsQueryResult = NonNullable<Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>>
export type PublicControllerGetLandingPageStatsQueryError = unknown

export const usePublicControllerGetLandingPageStats = <TData = Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetLandingPageStats>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetLandingPageStatsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPublicControllerGetLandingPageStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const publicControllerGetUnitById = (
    id: string,
 options?: SecondParameter<typeof publicControllerGetUnitByIdMutator>,signal?: AbortSignal
) => {
      
      
      return publicControllerGetUnitByIdMutator<PublicUnitResponseItem>(
      {url: `/api/public/unit/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getPublicControllerGetUnitByIdQueryKey = (id: string,) => {
    
    return [`/api/public/unit/${id}`] as const;
    }

    
export const getPublicControllerGetUnitByIdQueryOptions = <TData = Awaited<ReturnType<typeof publicControllerGetUnitById>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnitById>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetUnitByIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPublicControllerGetUnitByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof publicControllerGetUnitById>>> = ({ signal }) => publicControllerGetUnitById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnitById>>, TError, TData> & { queryKey: QueryKey }
}

export type PublicControllerGetUnitByIdQueryResult = NonNullable<Awaited<ReturnType<typeof publicControllerGetUnitById>>>
export type PublicControllerGetUnitByIdQueryError = unknown

export const usePublicControllerGetUnitById = <TData = Awaited<ReturnType<typeof publicControllerGetUnitById>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof publicControllerGetUnitById>>, TError, TData>, request?: SecondParameter<typeof publicControllerGetUnitByIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPublicControllerGetUnitByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Export publicly available units table
 */
export const publicControllerExport = (
    publicExportRequest: PublicExportRequest,
 options?: SecondParameter<typeof publicControllerExportMutator>,) => {
      
      
      return publicControllerExportMutator<Blob>(
      {url: `/api/public/export`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: publicExportRequest,
        responseType: 'blob'
    },
      options);
    }
  


export const getPublicControllerExportMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicControllerExport>>, TError,{data: PublicExportRequest}, TContext>, request?: SecondParameter<typeof publicControllerExportMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof publicControllerExport>>, TError,{data: PublicExportRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publicControllerExport>>, {data: PublicExportRequest}> = (props) => {
          const {data} = props ?? {};

          return  publicControllerExport(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PublicControllerExportMutationResult = NonNullable<Awaited<ReturnType<typeof publicControllerExport>>>
    export type PublicControllerExportMutationBody = PublicExportRequest
    export type PublicControllerExportMutationError = unknown

    export const usePublicControllerExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publicControllerExport>>, TError,{data: PublicExportRequest}, TContext>, request?: SecondParameter<typeof publicControllerExportMutator>}
) => {

      const mutationOptions = getPublicControllerExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    